import React from "react";
import classNames from "classnames";
import t from "@utils/t";
import useLang from "@hooks/useLang";
import { Container, Text, Image, Button, AnimateIn } from "@atoms";

const ImageCopy = ({
  button,
  copy,
  image,
  order,
  reverse,
  showDivider,
  subheading,
}) => {
  const lang = useLang();
  return (
    <section
      className={classNames("pb-20 pt-20 md:pb-28 md:pt-28", {
        "border-t-2 border-black": showDivider && order !== 0,
      })}
    >
      <Container>
        <AnimateIn
          preset="fadeUpFast"
          className="flex flex-wrap items-center gap-12 lg:gap-20"
        >
          <div
            className={classNames("flex flex-1 flex-col items-start", {
              "order-2 md:order-1": !reverse,
              "order-2": reverse,
            })}
          >
            {subheading && (
              <Text variant="label" className="mb-8">
                {subheading}
              </Text>
            )}
            <Text richText>{copy}</Text>
            {button?.url && (
              <Button
                to={button.url}
                className="mt-8"
                download={button.download || button.type === "asset"}
              >
                {button.text || t("Learn More", lang)}
              </Button>
            )}
          </div>
          <Image
            image={image}
            aspectRatio={[16, 9]}
            className={classNames("w-full md:w-1/2", {
              "order-1 md:order-2": !reverse,
              "order-1": reverse,
            })}
          />
        </AnimateIn>
      </Container>
    </section>
  );
};

ImageCopy.defaultProps = {};

export default ImageCopy;
